import type { FC, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Collapse, List, Typography } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "../../../icons/chevron-right";
import { ChevronDown as ChevronDownIcon } from "../../../icons/chevron-down";

interface SidebarItemsGroupProps {
  active: boolean;
  activeHref: string;
  icon: ElementType;
  subRoutes: Array<{ href: string; title: string }>;
  onClick: () => void;
  open: boolean;
  pinned: boolean;
  title: string;
}

export const SidebarItemsGroup: FC<SidebarItemsGroupProps> = ({
  active,
  activeHref,
  icon: Icon,
  subRoutes,
  onClick,
  open,
  pinned,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <List disablePadding sx={{ width: "100%" }}>
      <li>
        <Button
          endIcon={
            open ? <ChevronDownIcon fontSize="small" /> : <ChevronRightIcon fontSize="small" />
          }
          fullWidth
          onClick={onClick}
          startIcon={<Icon />}
          sx={{
            justifyContent: "flex-start",
            lineHeight: 0,
            minWidth: "fit-content",
            px: 1.25,
            py: 1.25,
            "& .MuiButton-startIcon": {
              color: active ? "primary" : "text.secondary",
              margin: 0,
            },
            "& .MuiButton-endIcon": {
              color: "action.disabled",
              display: pinned ? "flex" : "none",
              marginLeft: "auto",
            },
          }}
          variant="text"
        >
          <Typography
            color="textPrimary"
            sx={{
              color: active ? "primary" : "text.primary",
              display: pinned ? "flex" : "none",
              ml: 1.25,
            }}
            variant="inherit"
          >
            {t(title)}
          </Typography>
        </Button>
      </li>
      <Collapse in={open} unmountOnExit>
        <List disablePadding sx={{ width: "100%" }}>
          {subRoutes.map((subRoute) => {
            const isActive = activeHref === subRoute.href;

            return (
              <li key={subRoute.href}>
                <Button
                  component={RouterLink}
                  fullWidth
                  sx={{
                    color: isActive ? "primary" : "text.secondary",
                    fontWeight: 400,
                    justifyContent: "flex-start",
                    pl: 5,
                    whiteSpace: "nowrap",
                  }}
                  to={subRoute.href}
                  variant="text"
                >
                  {t(subRoute.title)}
                </Button>
              </li>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};
