import { useEffect } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Box, Button, CssBaseline, ThemeProvider, Typography } from "@material-ui/core";
import { initializeI18n } from "./i18n";
import { RTL } from "./components/rtl";

import { useSettings } from "./contexts/SettingsContext";

import routes from "./routes";
import { createCustomTheme } from "./theme";
import { useAuth0 } from "@auth0/auth0-react";
import store from "./store/ApplicationStore";
import Loader from "./components/atoms/Loader/Loader";

export const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const { isAuthenticated, user, isLoading, error, logout, getAccessTokenSilently } = useAuth0();

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently();

      store.auth.setToken(token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      store.user.setUserOrganizationId(user["https://platform.signalos.io/active_org_id"]);
      getToken();
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    initializeI18n(settings.language);
  }, [settings]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Loader />
        </Box>
      );
    }
    if (error) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Typography>Oops... {error.message}</Typography> <br />
          <Button variant="contained" onClick={() => logout()}>
            Logout
          </Button>
        </Box>
      );
    } else {
      return content;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={settings.direction}>
        <CssBaseline />
        {renderContent()}
      </RTL>
    </ThemeProvider>
  );
};
