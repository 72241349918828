import type { FC, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Typography } from "@material-ui/core";
import { ExternalLink as ExternalLinkIcon } from "../../../icons/external-link";

interface SidebarItemProps {
  active: boolean;
  external?: boolean;
  href: string;
  icon: ElementType;
  pinned: boolean;
  title: string;
}

export const SidebarItem: FC<SidebarItemProps> = ({
  active,
  external,
  href,
  icon: Icon,
  pinned,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <li>
      <Button
        component={RouterLink}
        endIcon={external && <ExternalLinkIcon sx={{ color: "action.disabled" }} />}
        fullWidth
        startIcon={<Icon />}
        target={external ? "_target" : "_self"}
        sx={{
          justifyContent: "flex-start",
          lineHeight: 0,
          minWidth: "fit-content",
          px: 1.25,
          py: 1.25,
          "& .MuiButton-startIcon": {
            color: active ? "primary" : "text.secondary",
            margin: 0,
          },
          "& .MuiButton-endIcon": {
            color: "action.disabled",
            display: pinned ? "flex" : "none",
            marginLeft: "auto",
          },
        }}
        to={href}
        variant="text"
      >
        <Typography
          color="textPrimary"
          sx={{
            color: active ? "primary" : "text.primary",
            display: pinned ? "flex" : "none",
            ml: 1.25,
          }}
          variant="inherit"
        >
          {t(title)}
        </Typography>
      </Button>
    </li>
  );
};
