import { useState, useEffect } from "react";
import type { FC } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Drawer, List } from "@material-ui/core";
import { NavbarMenuItem } from "../molecules/NavbarMenu/NavbarMenuItem";
import { Route } from "src/types/interfaces/Route";
import { routes } from "src/constans/routes";
import { NavbarMenuItemsGroup } from "../molecules/NavbarMenu/NavbarMenuItemsGroup";

interface NavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

export const NavbarMenu: FC<NavbarMenuProps> = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const [openedRoute, setOpenedRoute] = useState<Route | null>(null);
  const [activeRoute, setActiveRoute] = useState<Route | null>(null);
  const [activeHref, setActiveHref] = useState("");

  const handleToggleRoute = (route: Route): void => {
    if (openedRoute === route) {
      setOpenedRoute(null);
      return;
    }

    setOpenedRoute(route);
  };

  useEffect(() => {
    routes.forEach((route) => {
      if (route.subRoutes) {
        for (let index = 0; index < route.subRoutes.length; index++) {
          const active = matchPath({ path: route.subRoutes[index].href, end: true }, pathname);

          if (active) {
            setActiveRoute(route);
            setActiveHref(route.subRoutes[index].href);
            setOpenedRoute(route);
            break;
          }
        }
      } else {
        const active = matchPath({ path: route.href, end: true }, pathname);

        if (active) {
          setActiveRoute(route);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#2B2F3C",
          color: "#B2B7C8",
          display: "flex",
          flexDirection: "column",
          top: 64,
          maxHeight: "calc(100% - 64px)",
          width: "100vw",
        },
      }}
    >
      <List>
        {activeRoute &&
          routes.map((route) =>
            route.subRoutes ? (
              <NavbarMenuItemsGroup
                active={activeRoute?.title === route.title}
                activeHref={activeHref}
                key={route.title}
                subRoutes={route.subRoutes}
                onClose={onClose}
                onClick={() => handleToggleRoute(route)}
                open={openedRoute?.title === route.title}
                {...route}
              />
            ) : (
              <NavbarMenuItem
                active={activeRoute?.title === route.title}
                key={route.title}
                href={route.href}
                onClose={onClose}
                {...route}
              />
            )
          )}
      </List>
    </Drawer>
  );
};
