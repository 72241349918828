import type { FC, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@material-ui/core";
import { ChevronRight as ChevronRightIcon } from "../../../icons/chevron-right";
import { ChevronDown as ChevronDownIcon } from "../../../icons/chevron-down";

interface NavbarMenuItemsGroupProps {
  active: boolean;
  activeHref: string;
  icon: ElementType;
  subRoutes: Array<{ href: string; title: string }>;
  onClose: () => void;
  onClick: () => void;
  open: boolean;
  title: string;
}

export const NavbarMenuItemsGroup: FC<NavbarMenuItemsGroupProps> = ({
  active,
  activeHref,
  icon: Icon,
  subRoutes,
  onClose,
  onClick,
  open,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <List disablePadding sx={{ width: "100%" }}>
      <ListItemButton
        divider
        key={title}
        onClick={onClick}
        sx={{
          alignItems: "center",
          borderColor: "#3F455A",
          display: "flex",
          px: 3,
          py: 1.5,
          ...(active && {
            backgroundColor: "#383D4D",
            color: "#ffffff",
          }),
          "&:hover": {
            backgroundColor: "#383D4D",
            color: "#ffffff",
          },
        }}
      >
        <ListItemIcon
          sx={{
            color: "inherit",
            minWidth: 0,
            mr: 1,
          }}
        >
          <Icon />
        </ListItemIcon>
        <ListItemText
          primary={t(title)}
          primaryTypographyProps={{
            color: "inherit",
            variant: "caption",
          }}
        />
        {open ? (
          <ChevronDownIcon fontSize="small" sx={{ color: "#506176" }} />
        ) : (
          <ChevronRightIcon fontSize="small" sx={{ color: "#506176" }} />
        )}
      </ListItemButton>
      <Collapse in={open} unmountOnExit>
        <List disablePadding sx={{ width: "100%" }}>
          {subRoutes.map((subRoute) => {
            const isActive = activeHref === subRoute.href;

            return (
              <ListItemButton
                component={RouterLink}
                divider
                key={subRoute.href}
                onClick={onClose}
                sx={{
                  alignItems: "center",
                  borderColor: "#3F455A",
                  display: "flex",
                  px: 3,
                  py: 1.5,
                  ...(isActive && {
                    backgroundColor: "#383D4D",
                    color: "#ffffff",
                  }),
                  "&:hover": {
                    backgroundColor: "#383D4D",
                    color: "#ffffff",
                  },
                }}
                to={subRoute.href}
              >
                <ListItemText
                  primary={t(subRoute.title)}
                  primaryTypographyProps={{
                    color: "inherit",
                    variant: "caption",
                  }}
                  sx={{ my: 0 }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
};
