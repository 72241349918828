import { makeAutoObservable } from "mobx";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  userOrganizationId: string = null;

  setUserOrganizationId = (userOrganizationId: string) => {
    this.userOrganizationId = userOrganizationId;
  };
}

export default UserStore;
