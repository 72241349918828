import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        page: "Page",
        of: "of",
        cancel: "Cancel",
        confirm: "Confirm",
        delete: "Delete",
        required: "Field is required",
        yes: "Yes",
        no: "No",
        logout: "Log out",
        search: "Search",
        perPage: "Per page:",
        areYouSure: "Are You sure?",
        availableSoon: "Available soon",
      },
      users: {
        title: "Users",
        tab: "Users | SignalOS",
        table: {
          email: "Email",
          identifier: "Identifier",
          cardsNumbers: "Cards Numbers",
          details: "Details",
        },
      },
      keySets: {
        title: "Key sets",
        tab: "Key sets | SignalOS",
        details: "Details",
        edit: "Edit limit per user",
        list: {
          activeCards: "Active cards",
          actualUsage: "Actual Usage",
          cardsLimit: "Cards Limit",
          facility: "Facility",
          synchronize: "Synchronize",
          managers: "Managers",
          organization: "Organization",
          cardLimitPerUser: "Card limit per user",
          totalCardLimit: "Total card limit",
          limit: "Limit",
        },
        modal: {
          edit: {
            title: "Edit limit per user",
          },
        },
        toast: {
          usedUpCardLimit:
            "You have used up your card limit, contact your office manager to change the limit",
        },
      },
      keySetLimit: {
        title: "Limits",
        tab: "Limits | SignalOS",
        extras: "Invite new organization",
        list: {
          organizationName: "Organization Name",
          cardsLimit: "Cards Limit",
          userCardsLimit: "User Cards Limit",
          actualUsage: "Actual Usage",
          save: "Save",
        },
        dialog: {
          add: {
            title: "Add new organization",
          },
        },
        toast: {
          edit: {
            success: "Key Set Limit edited successfully",
          },
          add: {
            success: "Organization added successfully ",
          },
        },
        validation: {
          required: "This field is required",
        },
      },
      user: {
        invitations: {
          title: "Logs",
          status: "Status",
          code: "Code",
          mobileId: "Mobile id",
          installationId: "Installation id",
          externalInvitationId: "External invitation id",
          hidCredentialContainerId: "Hid credential container id",
          expiredAt: "Expired At",
          statusOptions: {
            pending: "Pending",
            failed: "Failed",
            acknowledged: "Acknowledged",
            expired: "Expired",
            cancelled: "Cancelled",
            notSupported: "Not supported",
            cancelFailed: "Cancel Failed",
          },
        },
        tabs: {
          details: "Details",
          invitations: "Invitations",
        },
        container: {
          title: "Devices",
          addCard: "Add Card",
          removeCard: "Remove Card",
          removeContainer: "Remove Container",
          systemVersion: "System Version",
          appVersion: "App version",
          device: "Device",
          status: "Status",
          statusOptions: {
            pending: "Pending",
            active: "Active",
            registered: "Registered",
            notEligible: "Not eligible",
            terminated: "Terminated",
            inactive: "Inactive",
            deleteInitiated: "Delete initiated",
            deleteFailed: "Delete failed",
          },
          manufacturer: "Manufacturer",
          hidVersion: "HID version",
          seosId: "SEOS ID",
          nfcCapability: "NFC capability",
          noMobileCardsCreated: "No mobile cards created",
          lastLogin: "Last Login",
        },
        card: {
          cardNumber: "Card number",
          status: "Status",
          partNumberFriendlyName: "Part number",
          disabled: "This card is already used",
          toast: {
            proccessMayTakeAFewMinutes: "The process may take a few minutes",
            addingCardFailed: "Please select one of the available cards",
          },
          statusOptions: {
            unbounded: "Unbounded",
            issueInitiated: "Issue initiated",
            issuing: "Issuing",
            issued: "Issued",
            revokeInitiated: "Revoke initiated",
            revoking: "Revoking",
            revokingFailure: "Revoking failure",
            revoked: "Revoked",
          },
        },
        keySetLimit: {
          title: "Limits",
          cardsLimit: "Cards limit",
          save: "Save",
        },
        hidPacUser: {
          title: "HID pac user",
        },
        dialog: {
          add: {
            title: "Add Card",
            provider: "Provider",
            cardNumber: "Card Number",
          },
          remove: {
            card: {
              doYouWant: "Do You want to remove this card.",
              thisCantBeUndone: "This action cant be undone.",
            },
            container: {
              doYouWant: "Do You want to remove this container.",
              thisCantBeUndone: "This action cant be undone.",
            },
          },
        },
        toast: {
          update: {
            keySetLimit: {
              success: "Successfully update cards limit",
            },
          },
          remove: {
            container: {
              success: "Successfully removed container ",
            },
            card: {
              success: "Successfully removed card ",
            },
          },
        },
        validation: {
          required: "This field is required",
        },
      },
    },
  },
  pl: {
    translation: {
      common: {
        page: "Strona",
        of: "z",
        cancel: "Anuluj",
        confirm: "Potwierdź",
        delete: "Usuń",
        required: "Pole jest wymagane",
        yes: "Tak",
        no: "Nie",
        logout: "Wyloguj",
        search: "Szukaj",
        perPage: "Na stronę:",
        areYouSure: "Czy na pewno?",
        toastSuccess: "Aplikacje ustawione jako domyślne poprawnie",
        availableSoon: "Dostępne wkrótce",
      },
      users: {
        title: "Użytkownicy",
        tab: "Użytkownicy | SignalOS",
        table: {
          email: "Email",
          identifier: "Identyfikator",
          cardsNumbers: "Numery Kart",
          details: "Szczegóły",
        },
      },
      keySets: {
        title: "Key sets",
        tab: "Key sets | SignalOS",
        details: "Szczegóły",
        edit: "Edytuj limit kart na użytkownika",
        list: {
          activeCards: "Aktywne karty",
          cardsLimit: "Limit kart",
          actualUsage: "Wykorzystana ilość kart",
          facility: "Obiekt",
          synchronize: "Synchronizuj",
          managers: "Menadżerowie",
          organization: "Organizacja",
          cardLimitPerUser: "Limit kart na użytkownika",
          totalCardLimit: "Całkowity limit kart",
          limit: "Limit",
        },
        modal: {
          edit: {
            title: "Edytuj limit kart na użytkownika",
          },
        },
        toast: {
          usedUpCardLimit:
            "Wykorzystałeś  limit kart, skontaktuj sie z swoim office managerem aby zmienić limit",
        },
      },
      keySetLimit: {
        title: "Limity",
        tab: "Limity | SignalOS",
        extras: "Zaproś nową organizacje",
        list: {
          organizationName: "Nazwa organizacji",
          cardsLimit: "Limit kart",
          actualUsage: "Wykorzystana ilość kart",
          userCardsLimit: "Limit kart na użytkownika",
          save: "Zapisz",
        },
        dialog: {
          add: {
            title: "Dodaj nową organizacje",
          },
        },
        toast: {
          edit: {
            success: "Pomyślnie zedytowano key set limit ",
          },
          add: {
            success: "Pomyślnie dodano nową organizację",
          },
        },
        validation: {
          required: "To pole jest wymagane",
        },
      },
      user: {
        tabs: {
          details: "Szczegóły",
          invitations: "Zaproszenia",
        },
        invitations: {
          title: "Logi",
          status: "Status",
          code: "Kod",
          mobileId: "Id telefonu",
          installationId: "Id instalacji",
          externalInvitationId: "Id zaproszenia zewnętrznego",
          hidCredentialContainerId: "Id kontenera hid",
          expiredAt: "Ważny do",
          statusOptions: {
            pending: "Oczekujące",
            failed: "Nieudane",
            acknowledged: "Potwierdzone",
            expired: "Wygasłe",
            cancelled: "Anulowane",
            notSupported: "Nieobsłużone",
            cancelFailed: "Anulowanie nieudane",
          },
        },
        container: {
          title: "Urządzenia",
          addCard: "Dodaj kartę",
          removeCard: "Usuń kartę",
          removeContainer: "Usuń kontener",
          systemVersion: "Wersja systemu",
          appVersion: "Wersja aplikacji",
          hidVersion: "Wersja HIDu",
          device: "Urządzenie",
          manufacturer: "Producent",
          nfcCapability: "Możliwość NFC",
          status: "Status",
          noMobileCardsCreated: "Brak utworzonych kart mobilnych",
          statusOptions: {
            pending: "Oczekujące",
            active: "Aktywne",
            registered: "Zarejestrowane",
            notEligible: "Nie kwalifikuję się",
            terminated: "Straciło ważność",
            inactive: "Nieaktywne",
            deleteInitiated: "Usunięcie zainicjowane",
            deleteFailed: "Usunięcie nieudane",
          },
          seosId: "SEOS ID",
          lastLogin: "Ostatnie Logowanie",
        },
        hidPacUser: {
          title: "HID pac user",
        },
        keySetLimit: {
          title: "Limity",
          cardsLimit: "Limit kart",
          save: "Zapisz",
        },
        card: {
          cardNumber: "Numer Karty",
          status: "Status",
          disabled: "Ta karta jest już wykorzystana",
          partNumberFriendlyName: "Numer grupy",
          toast: {
            addingCardFailed: "Wybierz jedną z dostępnych kart",
            proccessMayTakeAFewMinutes: "Proces może zająć parę minut",
          },
          statusOptions: {
            unbounded: "Bez limitu",
            issueInitiated: "Wydawanie zainicjowane",
            issuing: "Wydawanie",
            issued: "Wydana",
            revokeInitiated: "Unieważnianie inicjowania",
            revoking: "Unieważnianie",
            revokingFailure: "Unieważnianie nieudane",
            revoked: "Unieważnione",
          },
        },
        dialog: {
          add: {
            title: "Dodaj Kartę",
            provider: "Dostawca",
            cardNumber: "Numer karty",
          },
          remove: {
            card: {
              doYouWant: "Czy chcesz usunąć tę kartę.",
              thisCantBeUndone: "Tego nie da się cofnąć.",
            },
            container: {
              doYouWant: "Czy chcesz usunąć ten kontener.",
              thisCantBeUndone: "Tego nie da się cofnąć.",
            },
          },
        },
        toast: {
          update: {
            keySetLimit: {
              success: "Pomyślnie zedytowano limit kart",
            },
          },
          remove: {
            container: {
              success: "Pomyślnie usunięto kontener ",
            },
            card: {
              success: "Pomyślnie usunięto kartę",
            },
          },
        },
        validation: {
          required: "To pole jest wymagane",
        },
      },
    },
  },
};

export const initializeI18n = (lng: string): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
