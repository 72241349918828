import type { FC, ElementType } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListItemButton, ListItemIcon, ListItemText } from "@material-ui/core";
import { ExternalLink as ExternalLinkIcon } from "../../../icons/external-link";

interface NavbarMenuItemProps {
  active: boolean;
  external?: boolean;
  href: string;
  icon: ElementType;
  onClose: () => void;
  title: string;
}

export const NavbarMenuItem: FC<NavbarMenuItemProps> = ({
  active,
  external,
  href,
  icon: Icon,
  onClose,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <ListItemButton
      component={RouterLink}
      divider
      onClick={onClose}
      sx={{
        alignItems: "center",
        borderColor: "#3F455A",
        display: "flex",
        px: 3,
        py: 1.5,
        ...(active && {
          backgroundColor: "#383D4D",
          color: "#ffffff",
        }),
        "&:hover": {
          backgroundColor: "#383D4D",
          color: "#ffffff",
        },
      }}
      target={external ? "_target" : "_self"}
      to={href}
    >
      <ListItemIcon
        sx={{
          color: "inherit",
          minWidth: 0,
          mr: 1,
        }}
      >
        <Icon />
      </ListItemIcon>
      <ListItemText
        primary={t(title)}
        primaryTypographyProps={{
          color: "inherit",
          variant: "caption",
        }}
      />
      {external && <ExternalLinkIcon sx={{ color: "#506176" }} />}
    </ListItemButton>
  );
};
