import { Suspense, lazy } from "react";
import type { PartialRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "./layout/MainLayout";

import { LoadingScreen } from "./components/LoadingScreen";

import AuthGuard from "./components/Guards/AuthGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const Users = Loadable(
  lazy(() =>
    import("./containers/users").then((module) => ({
      default: module.UsersContainer,
    }))
  )
);

const KeySetsLimit = Loadable(
  lazy(() =>
    import("./containers/keySetsLimit").then((module) => ({
      default: module.KeySetsLimitContainer,
    }))
  )
);

const User = Loadable(
  lazy(() =>
    import("./containers/user").then((module) => ({
      default: module.UserContainer,
    }))
  )
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

const routes: PartialRouteObject[] = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Navigate to="/users" replace />,
      },
    ],
  },
  {
    path: "users",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Users />,
      },
      {
        path: "/:userId",
        element: <User />,
      },
    ],
  },
  {
    path: "key-sets",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <KeySetsLimit />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;
